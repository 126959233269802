<template>
    <div>
        <!-- CAROUSEL-->
    <!-- <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide       
                v-if="resources!=null"         
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>
     -->
        <br>

        <!-- <h1 class="titles">
          <span style="color: #d82125">M</span>
          <span style="color: #d02175">u</span>
          <span style="color: #f6c921">n</span>
          <span style="color: #d02175">i</span>
          <span style="color: #4d944a">c</span>
          <span style="color: #07aaba">i</span>
          <span style="color: #d82125">p</span>
          <span style="color: #d02175">i</span>
          <span style="color: #f6c921">o</span>
        </h1> -->
        
        <h1 class="titles">
          <span style="color: #8b8c8c">¿Dónde comer?</span>
        </h1>



        <br><br>

    <b-row >

        <b-col>
            <b-tabs content-class="mt-3" justified>

                <b-tab active>
                     <template #title>
                        <strong>Restaurantes</strong>
                    </template>
                    <br>
                    <h2 class="titles">
                                <span style="color: #8b8c8c">Restaurantes</span>
                    </h2>
                    <br>
                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='R'" ></b-col>
                            <b-col cols="12" sm="10" md="5" lg="3"  class="mb-3" v-if="item.tipo=='R'" >
                                <b-container  style="cursor:pointer;">
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/2%20restaurantes/'+item.img+'.jpg'" fluid rounded class="c-shadow-sm" ></b-img>
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/2%20restaurantes/'+item.img+'.jpeg'" fluid rounded class="c-shadow-sm" ></b-img>
                                </b-container>
                            </b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='R'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='R'" ></b-col>
                        
                    </b-row>
                </b-tab>


                <b-tab >
                     <template #title>
                        <strong>Bares</strong>
                    </template>
                    <br>
                    <h2 class="titles">
                                <span style="color: #8b8c8c">Bares</span>
                    </h2>
                    <br>
                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='B'" ></b-col>
                            <b-col cols="12" sm="10" md="5" lg="3"  class="mb-3" v-if="item.tipo=='B'" >
                                <b-container  style="cursor:pointer;">
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/2%20restaurantes/'+item.img+'.jpg'" fluid rounded class="c-shadow-sm" ></b-img>
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/2%20restaurantes/'+item.img+'.jpeg'" fluid rounded class="c-shadow-sm" ></b-img>
                                </b-container>
                            </b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='B'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='B'" ></b-col>
                        
                    </b-row>
                </b-tab>

                <b-tab >
                     <template #title>
                        <strong>Cafeterías</strong>
                    </template>
                    <br>
                    <h2 class="titles">
                                <span style="color: #8b8c8c">Cafeterías</span>
                    </h2>
                    <br>
                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='C'" ></b-col>
                            <b-col cols="12" sm="10" md="5" lg="3"  class="mb-3" v-if="item.tipo=='C'" >
                                <b-container  style="cursor:pointer;">
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/2%20restaurantes/'+item.img+'.jpg'" fluid rounded class="c-shadow-sm" ></b-img>
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/2%20restaurantes/'+item.img+'.jpeg'" fluid rounded class="c-shadow-sm" ></b-img>
                                </b-container>
                            </b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='C'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='C'" ></b-col>
                        
                    </b-row>
                </b-tab>


                <b-tab >
                     <template #title>
                        <strong>Snacks, Pizzas y Churrerías</strong>
                    </template>
                    <br>
                    <h2 class="titles">
                                <span style="color: #8b8c8c">Snacks, Pizzas y Churrerías</span>
                    </h2>
                    <br>
                    <b-row  align-h="center"  v-for="(item, idx) in datos " :key="idx" >
                            <b-col cols="12" class="mt-4" v-if="item.tipo=='S'||item.tipo=='P'||item.tipo=='CH'" ></b-col>
                            <b-col cols="12" sm="10" md="5" lg="3"  class="mb-3" v-if="item.tipo=='S'||item.tipo=='P'||item.tipo=='CH'" >
                                <b-container  style="cursor:pointer;">
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/2%20restaurantes/'+item.img+'.jpg'" fluid rounded class="c-shadow-sm" ></b-img>
                                <b-img :src="'https://www.teotihuacan.gob.mx/turismot/2%20restaurantes/'+item.img+'.jpeg'" fluid rounded class="c-shadow-sm" ></b-img>
                                </b-container>
                            </b-col>
                            <b-col cols="10" sm="8" md="5" lg="4" v-if="item.tipo=='S'||item.tipo=='P'||item.tipo=='CH'">
                                <b-row><h5><b>{{item.nombre}}</b></h5></b-row>
                                <b-row><h6><b-icon icon="geo-fill"/> {{item.direccion}}</h6></b-row>
                                <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
                                <b-row v-if="item.url!=null && item.url!='' && !item.url.includes('@')"><h6><b-icon icon="link"/><a :href="item.url" target="_blank"> Visitar Sitio</a></h6></b-row>
                            </b-col>
                            <b-col cols="12" class="mb-5" v-if="item.tipo=='S'||item.tipo=='P'||item.tipo=='CH'" ></b-col>
                        
                    </b-row>
                </b-tab>

            </b-tabs>
        </b-col>
    </b-row>

    </div>
</template>
<script>
  export default {
    components: {
    },
    mounted: function(){
     
    },
    computed: {
      
    },
    data() {
      return {
        resources:null,
        datos:[
          {img:'1',nombre:' LAS MENTADAS Grill & Bar',direccion:'Av. Hidalgo #2 col. Centro San Juan Teotihuacán ',telefono:'5941085313   5561465562',url:'https://www.facebook.com/LasMentadasTeotihuacan/',tipo:'B'},
          {img:'4',nombre:'Aldea Vikinga',direccion:'Adolfo Lopez Mateos #18 Teotihuacán Centro',telefono:' 594 108 0541',url:'https://es-la.facebook.com/AldeaVikinga/',tipo:'B'},
          {img:'7',nombre:' Cantaritos Taco Bar',direccion:'Vicente Guerrero San Lorenzo Tlalmimilolpan',telefono:' 55 1813 7058',url:'https://es-la.facebook.com/Cantaritos-Taco-Bar-387931208676958/',tipo:'B'},
          {img:'8',nombre:' Jacaré',direccion:'Plaza de la Parroquia #2 San Juan Centro ',telefono:' 55 8031 5338',url:'https://jacare-bs.negocio.site/',tipo:'B'},
          {img:'12',nombre:' Tricelta Bistro Café ',direccion:'Guadalupe Victoria No.17 Local 8 San Juan Teotihuacán Col. Centro ',telefono:'5539097614',url:'https://www.facebook.com/TriceltaBistro',tipo:'C'},
          {img:'17',nombre:'Restaurante Hospedaje Y Salón Los Ahuehuetes',direccion:'Adolfo López Mateos #16 Col. Centro San Juan Teotihuacán ',telefono:'5949561339   5584121666',url:'Facebook Restaurante los ahuehuetes Teotihuacán',tipo:'R'},
          {img:'19',nombre:' Restaurante La Cueva',direccion:'Cda. Hombres Ilustres y del Puente, San Francisco Mazapa Teotihuacán Estado de México',telefono:'5946881261   5588361190',url:'https://www.facebook.com/La-Cueva-Teotihuacán-407806209714344/',tipo:'R'},
          {img:'21',nombre:' Restaurante La Gruta',direccion:'Circuito Arqueológico, Av. del Puente S/N, 55820 San Francisco Mazapa, Méx.',telefono:'55 5191 9799',url:'https://lagruta.mx/',tipo:'R'},
          {img:'24',nombre:' Tlacaelel Restaurante & Boutique',direccion:'Circuito Arqueologico de las Piramides S/N entre puerta 1 y 2 Teotihuacán Estado de México ',telefono:'01 5949332290',url:'Https://restaurantetlacaelel.com',tipo:'R'},
          {img:'25',nombre:' Restaurante el Sol ',direccion:'Av. Periferico #60 San Francisco Mazapa Teotihuacán Estado de México ',telefono:'5531523196',url:'http://teotihuacan.nguideqr.mx/RElSol.html',tipo:'R'},
          {img:'30',nombre:' Restaurante las Palmas',direccion:'Continuación López Mateos número 55850 Teotihuacán Estado de México',telefono:'5612 83 41 94',url:'www.hotelpalmasteotihuacan.com',tipo:'R'},
          {img:'32',nombre:' Restaurante clotilde',direccion:'Plaza cristal Avenida Miguel Hidalgo 23A Teotihuacán centro 55800 San Juan Teotihuacán',telefono:'55 4588 4137',url:'https://restauranteclotilde.mx/',tipo:'R'},
          {img:'33',nombre:'Restaurante mi México lindo ',direccion:'calle pirámides sin número purificación 5510 San Juan Teotihuacán México',telefono:'55 2531 7693',url:'https://es.restaurantguru.com/Mi-Mexico-Lindo-Teotihuacan-de-Arista-2',tipo:'R'},
          {img:'34',nombre:' Restaurante el balcón',direccion:'Calle Bravo centro 55800 San Juan Teotihuacán de arista México',telefono:'594 95 89 042',url:'https://www.elbalconteotihuacan.com/',tipo:'R'},
          {img:'37',nombre:' Conejo de la luna',direccion:'circuito arqueologico s/n, Purificacion, 55810 San Juan Teotihuacan de Arista, Méx.',telefono:'594 956 8529',url:'https://m.facebook.com/mezcalesartesanales',tipo:'R'},
          {img:'39',nombre:' Restaurante la cañada',direccion:'Pirámides, Purificación, 55810 San Juan Teotihuacan de Arista, Méx',telefono:'5621 78 3816 5518186260',url:'https://es.restaurantguru.com/La-Canada-Teotihuacan-de-Arista',tipo:'R'},
          {img:'44',nombre:' La Cabaña',direccion:'Tuxpan Circuito Arqueológico de Teotihuacan, San Juan Teotihuacán 55830 México',telefono:'594 101 6083',url:'https://www.facebook.com/lacabana24teotihuacan/?rf=185799891506144',tipo:'R'},
          {img:'45',nombre:'Restaurante quinto sol',direccion:'Av. Miguel Hidalgo 26, Centro, 55800 San Juan Teotihuacan de Arista, Méx.',telefono:'01 594 956 1881                       5538 53 4870',url:'https://www.hotelquintosol.mx/',tipo:'R'},
          {img:'49',nombre:' Mictlán Restaurante subterraneo',direccion:'55853 San Juan Teotihuacan de Arista, Méx.',telefono:'+52 55 4003 3262',url:'https://es-la.facebook.com/MictlanSubterraneo/',tipo:'R'},
          {img:'54',nombre:'54 La Caverna',direccion:'Purificación',telefono:'5949568226',url:'https://es.foursquare.com/v/la-caverna-de-teotihuacan/58cd799d2ec3646674f9f851',tipo:'R'},
          {img:'55',nombre:'55 El Ranchito',direccion:'Circuito Arqueologico Purificacion',telefono:' 594 956 1583',url:'https://www.facebook.com/pages/category/Mexican-Restaurant/El-Ranchito-Teotihuacan-144358422898720/',tipo:'R'},
          {img:'57',nombre:'57 KFC',direccion:'Teotihuacán col. CeGuadalupe Victoria 8, Centro, 55800 San Juan Teotihuacan de Arista, Méx.ntro ',telefono:' 55 1515 4747',url:'',tipo:'R'},
          {img:'64',nombre:' Little Cearsers ',direccion:'Guadalupe Victoria 1, Teotihuacan Centro, 55800 San Juan Teotihuacan de Arista, Méx.',telefono:' 594 688 0541',url:'',tipo:'p'},
          {img:'65',nombre:' Domios Pizzas',direccion:'Teotihuacán col. Centro ',telefono:'594 688 2141',url:'',tipo:'P'},
          {img:'66',nombre:' Don Minchos',direccion:'Teotihuacán col. Centro ',telefono:'594 95 6 1793',url:'https://donmincho.com.mx/',tipo:'P'},
          {img:'68',nombre:' Churreria porfirio ',direccion:'Av. Guadalupe Victoria #4 Colonia Centro, San Juan Teotihuacán de Arista.',telefono:'',url:'',tipo:'CH'},



         
{img:'0',nombre:'Pancho Villa´s Beer Garden',direccion:' Av de la Amistad 27, 55850 San Francisco Mazapa, Méx.',telefono:' 594 108 1160',url:'https://es.restaurantguru.com/Beer-Garden-Pancho-Villa-San-Francisco-Mazapa',tipo:'B'},

{img:'2',nombre:'Restaurante & Bar El Mitotl',direccion:'Circuito empedrado, calle durazno #21 San Sebastián Xolalpa Teotihuacán',telefono:'5949561444  WhatsApp: 5551399997',url:'https://www.facebook.com/ElMitoteotihuacanrestaurante/        ',tipo:'B'},
{img:'3',nombre:' Hacienda El Ritual Teotihuacán',direccion:'Av. Centenario 17 San Francisco Mazapa Teotihuacán Estado de México',telefono:'5560757899',url:'https://www.facebook.com/cantaritosteotihuacan/',tipo:'B'},

{img:'5',nombre:' Burro Chelero',direccion:'Callon 25 Regimiento #4 San Juan Teotihuacán',telefono:' 729 623 2716',url:'https://es-la.facebook.com/burrochelerooficial/',tipo:'B'},
{img:'6',nombre:' Los Crudos de Sinaloa ',direccion:'Calle 25 Regimiento #13',telefono:' 55 5948 9888',url:'',tipo:'B'},

{img:'8a',nombre:' PoolRock Teotihuacán',direccion:'Calle Doctor Ignacio Sandoval 34, purificación ',telefono:'5518256961',url:'http://facebook.com/poolrockteo',tipo:'B'},

{img:'9a',nombre:'Cervecería Artesanal Cien Mascaras',direccion:'Carretera Federal México Tulancingo Km 27.200 San Francisco Mazapa #8',telefono:'5540883498-5544490336',url:'https://www.facebook.com/cerveceriacienmascaras',tipo:'B'},

{img:'',nombre:'CAFETERIAS',direccion:'',telefono:'',url:'',tipo:''},
{img:'9',nombre:'Cafetería Café Teo',direccion:'Av. del puente #2 San Francisco Mazapa Teotihuacán',telefono:'5621844299',url:'https://m.facebook.com/Cafeteotihuacan/?ref=page_internal',tipo:'C'},
{img:'10',nombre:' Me Latte Café ',direccion:'Melchor Ocampo #1 Barrio de Puxtla Teotihuacán Estado de México',telefono:'5521372962',url:'https://www.facebook.com/melattecafet/',tipo:'C'},
{img:'11',nombre:'Corazón De Manzana',direccion:'Calle Palma #1 Col. Centro San Juan Teotihuacán',telefono:'5523123261',url:'Facebook: Corazon de Manzana ',tipo:'C'},



{img:'13',nombre:'Chocolate macondo',direccion:'Adolfo López Mateos número 5 Teotihuacán centro ',telefono:'55 4549 8650',url:'Chocolatemacondo',tipo:'C'},
{img:'14',nombre:' cafetal',direccion:'55800, Pzla. de la Parroquia 4, Teotihuacan Centro, San Juan Teotihuacan de Arista, Méx.',telefono:'735 127 1209',url:'https://www.facebook.com/el.cafetal.35',tipo:'C'},
{img:'15',nombre:' Helados Santa lara',direccion:'Juárez 10, Centro, 55800 San Juan Teotihuacan de Arista, Méx.',telefono:' 594 956 2840',url:'',tipo:'C'},
{img:'',nombre:'RESTAURANTES',direccion:'',telefono:'',url:'',tipo:''},
{img:'16',nombre:' Restaurante y hospedaje los Adobes',direccion:'Canteroco #12 Col. Centro San Juan Teotihuacán ',telefono:'5949561287   5534425026',url:'Facebook restaurantelosadobesteotihuaca',tipo:'R'},

{img:'18',nombre:'Restaurante Quinta La India Bonita',direccion:'Cerrada de Allende s/n San Francisco Mazapa Teotihuacán Estado de México ',telefono:'5546868222',url:'https://nguide.ar-living360.mx/Restaurantes/R-Quinta-NV.html',tipo:'R'},

{img:'20',nombre:' restaurante la terraza ',direccion:'Av. Hidalgo#5 Interior 6 Col. Centro San Juan Teotihuacán ',telefono:'5548670048',url:'**',tipo:'R'},

{img:'22',nombre:' Resataurante Tonalli ',direccion:'Carretera Piramides s/n Purificacion Teotihuacán Estado de México ',telefono:'5517047398',url:'https://www.facebook.com/jardindeeventostonalli/',tipo:'R'},
{img:'23',nombre:' Rancho Azteca ',direccion:'Circuito Arqueologico de las Piramides S/N entre puerta 1 y 2 Teotihuacán Estado de México ',telefono:'5949568229',url:'Https://restauranteranchoazteca.com',tipo:'R'},




{img:'26',nombre:' Trinches Tacos',direccion:'Crus de la Mision #7 San Juan Teotihuacán Centro',telefono:'5566146322',url:'Facebook e Instagram: @trinchestacos',tipo:'R'},
{img:'27',nombre:' Itakatl Restaurant',direccion:'Tuxpan, 55830 San Francisco Mazapa Teotihuacán Estado de México',telefono:'5522717520',url:'https://maps.app.goo.gl/GCxwDP4ZFkqznMCf8',tipo:'R'},
{img:'28',nombre:' Canta y no Llores ',direccion:'Ignacio Sandoval 34, San Juan Teotihuacán Col. Centro ',telefono:'5518256961',url:'https://m.facebook.com/cantaynolloresteo/                    ',tipo:'R'},
{img:'29',nombre:' El rinconcito Teotihuacán ',direccion:'Av. Cuahutemoc 15 San Juan Teotihuacán Col. Centro',telefono:'5531040834',url:'https://www.facebook.com/elrinconteoti/',tipo:'R'},

{img:'31',nombre:' Restaurante Josefa y Grill',direccion:'Avenida México purificación 55804 San Juan Teotihuacán',telefono:'55 3923 4321',url:'https://es.restaurantguru.com',tipo:'R'},



{img:'35',nombre:' Restaurante Rancho la cofradía',direccion:'Adolfo López Mateos número 12 Teotihuacán centro 55800 San Juan Teotihuacán',telefono:'5530 2075 21',url:'https://es-la.facebook.com/restaurantranchocofradia/',tipo:'R'},
{img:'36',nombre:' Restaurante pueblo mágico',direccion:'Norte norte número 5 Teotihuacán centro 55800 San Juan Teotihuacán',telefono:'594 68 80523',url:'https://es.restaurantguru.com/Cafe-y-Restaurante-Pueblo-Magico-Teotihuacan-de-Arista',tipo:'R'},

{img:'38',nombre:' Restaurante el sabor de mis sabores',direccion:'Bnuevo mexico, no. 1 barrio de puxtla 55810, san juan teothuacan',telefono:'55 37 41 70 85/83',url:'https://www.facebook.com/ElsabordemisSabores/',tipo:'R'},

{img:'40',nombre:' Restaurante el campestre',direccion:'Puerta 2, Pirámides, Purificación, 55810 San Juan Teotihuacan de Arista, Méx.',telefono:'55 3527 3592',url:'https://es.restaurantguru.com/Comida-Tipica-Campestre-San-Francisco-Mazapa',tipo:'R'},
{img:'41',nombre:' restaurante internacional tlaxko',direccion:'av. Piramides no. 12 cantro 55850 san juan teotihuacan de arista',telefono:'(594) 9583074',url:'http://restintertlaxko.com.mx/contacto/',tipo:'R'},
{img:'42',nombre:' Hotel Vicalis & Restaurante',direccion:'Circuito periférico s/n San Sebastián xoloalpan, 55824 San Juan Teotihuacan de Arista, Méx.',telefono:' 55 2105 6452',url:'https://www.hoteles.com/ho1169193152/vicalis-teotihuacan-mexico/',tipo:'R'},
{img:'43',nombre:' HOTEL MAYAHUEL',direccion:'Felipe Carrillo Puerto No. 3, San Francisco Mazapa, 55830, Circuito Zona Arqueológica Circuito Zona Arqueológica de Teotihuacan entre puerta 4 y 5. Atrás de la Pirámide del Sol San Juan Teotihuacán',telefono:'01 (594) 958 2836 55 3659 2183',url:'https://www.mayahuelteotihuacan.com/',tipo:'R'},


{img:'46',nombre:' Mi tiempo',direccion:'Periférico Sur S/N, 55800 San Sebastián Xolalpa, Méx.',telefono:'55 5836 9020',url:'https://www.villasteo.com/',tipo:'R'},
{img:'47',nombre:'La finca ',direccion:'Cruz de La Misión 65, Centro, 55800 San Juan Teotihuacan de Arista, Méx.',telefono:'',url:'',tipo:'R'},
{img:'48',nombre:'El comedor del chef',direccion:'Circuito Arqueológico Local 9, San Sebastian, Teotihuacan Centro, 55824 San Sebastián Xolalpa, Méx.',telefono:' 594 101 6176',url:'https://es.restaurantguru.com/El-Nueve-el-Comedor-del-Chef-San-Sebastian-Xolalpa',tipo:'R'},

{img:'50',nombre:' Gran Teocalli ',direccion:' Autopista, México-pirámides Kilómetro 21.5, 55800 San Juan Teotihuacan de Arista, Méx.',telefono:'594 956 3267',url:'http://www.granteocalli.com.mx/',tipo:'R'},
{img:'51',nombre:' Restaurante Sunna',direccion:'Cicuito Arqueológico entre puerta 1 y puerta 2 ',telefono:'55 6079 6472',url:'https://m.facebook.com/sunnavibrademar/',tipo:'R'},
{img:'52',nombre:'52 La pancita Peña',direccion:'Calle de la solidaridad S/N San Sebastián Xolalpa',telefono:'5541307077',url:'https://es-la.facebook.com/pancitapena.teotihuacan/',tipo:'R'},
{img:'53',nombre:'53 Barbacoa Los Suarez ',direccion:'C. Abasolo 48, Centro, 55810 Centro, Méx.',telefono:'55 2746 3931',url:'https://es-la.facebook.com/barbacoaluissuarez/',tipo:'R'},


{img:'56',nombre:'56 Tei Sho Ku',direccion:'Av Cuauhtemoc 1, Centro, 55800 San Juan Teotihuacan de Arista, Méx.',telefono:' 55 3905 1682',url:'https://es-la.facebook.com/TEI-SHO-KU-Taiy%C3%B5-to-tsuki-673205576478807/',tipo:'R'},

{img:'58',nombre:'Mercado Municipal Hidalgo',direccion:' Bravo s/n, Teotihuacan Centro, 55800 San Juan Teotihuacan de Arista, Méx.',telefono:'',url:'',tipo:'R'},
{img:'59',nombre:' Heiwa to Shinpo (Comida japonesa)',direccion:'Adolfo López Mateos 13, Teotihuacan Centro, 55800 San Juan Teotihuacan de Arista, Méx.',telefono:'594 956 1898',url:'https://es-la.facebook.com/pages/category/Japanese-Restaurant/Heiwa-to-Shinpo-1592818334280618/',tipo:'R '},
{img:'',nombre:'SNACKS',direccion:'',telefono:'',url:'',tipo:''},
{img:'60',nombre:' Snack´s Mariscos-Bar Mar & Tierra',direccion:'Nicolás Bravo #2 Col. Centro San Juan Teotihuacán ',telefono:'5949563202',url:'FacebookSNACK´S MARISCOS MAR & TIERRA',tipo:'S'},
{img:'61',nombre:' DISFRUTA Bar de jugos y snack´s saludables',direccion:'Calle Cruz de la Misión #7 Col. Centro San Juan Teotihuacán ',telefono:'5528055275',url:'Facebook Disfruta   Instagram disfrutateotihuacan',tipo:'S'},
{img:'62',nombre:' La Patata  Pa´tato ',direccion:'Cda.16 de Septiembre Centro San Juan Teotihuacán ',telefono:'5568564646',url:'Facebook: La Patata Pa´tato    instagram: la_patata_patato',tipo:'S'},
{img:'63',nombre:' Snaks v8',direccion:' La Palma 4, Purificacion, 55810 San Juan Teotihuacan de Arista, Méx.',telefono:' 771 205 5723',url:'https://www.allbiz.mx/v-alitas-picositas-771-205-5723',tipo:'S'},
{img:'',nombre:'Pizzas',direccion:'',telefono:'',url:'',tipo:''},



{img:'67',nombre:' Pizzas Gutierrez',direccion:'C. 25 Regimiento, Centro, 55800 San Juan Teotihuacan de Arista, Méx.',telefono:' 55 2570 0335',url:'https://es.restaurantguru.com/Gutierrez-Pizza-Teotihuacan-de-Arista',tipo:'P'},
{img:'',nombre:'Churrería',direccion:'',telefono:'',url:'',tipo:''},



        ]
      }
    }
    
  }
</script>